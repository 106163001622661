<template>
  <div class="pa-9">
    <ValidationObserver ref="add_book">
      <h4>Tên sách</h4>
      <ValidationProvider
          name="title"
          rules="required"
          v-slot="{ errors }"
      >
        <v-text-field
            outlined
            dense
            :name="'title'"
            v-model="book.title"
            :placeholder="'Nhập title sách'"
        />
        <span class="validate-error text-danger text-danger">
              {{ errors[0] }}
            </span>
      </ValidationProvider>
      <h4>Tác giả</h4>
      <ValidationProvider
          name="title"
          rules="required"
          v-slot="{ errors }"
      >
        <v-text-field
            outlined
            dense
            :name="'title'"
            v-model="book.author"
            :placeholder="'Nhập tên tác giả'"
        />
        <span class="validate-error text-danger text-danger">
              {{ errors[0] }}
            </span>
      </ValidationProvider>
      <h4>Upload ảnh bìa(170x200)</h4>
      <v-file-input
          class="pl-1"
          id="file_input1"
          prepend-icon="mdi-camera"
          v-model="image"
          label=""
          @change="onChangeFileUpload"
          @click:clear="clearImage"
      ></v-file-input>
      <div>
        <img v-if="book.url" :src="book.url" width="200px"/>
      </div>
      <span class="validate-error text-danger">{{ imageError }}</span>
      <h4>Upload ảnh chi tiết(500x500)</h4>
      <v-file-input
          class="pl-1"
          id="file_input2"
          prepend-icon="mdi-camera"
          v-model="images"
          label=""
          multiple
          @change="onChangeMultipleFileUpload"
          @click:clear="clearImage"
      ></v-file-input>
      <div v-if="book.images && book.images.length > 0">
        <v-row>
          <v-col cols="4" v-for="(url,i) in book.images" :key="i">
            <div class="block-image">
              <v-icon large class="icon" @click="deleteImage(i)">mdi-close-circle-outline</v-icon>
              <img v-if="url" :src="url" width="200px"/>
            </div>
          </v-col>
        </v-row>

      </div>
      <span class="validate-error text-danger">{{ imageError2 }}</span>
      <h4>Giá sách</h4>
      <ValidationProvider name="price" rules="required|number" v-slot="{ errors }">
        <vuetify-money
            v-model="book.price"
            v-bind:label="label"
            v-bind:placeholder="placeholder"
            v-bind:readonly="readonly"
            v-bind:disabled="disabled"
            v-bind:outlined="outlined"
            v-bind:clearable="clearable"
            v-bind:valueWhenIsEmpty="valueWhenIsEmpty"
            v-bind:options="options"
        />
        <span class="validate-error text-danger text-danger">
              {{ errors[0] ? 'Giá tiền không được để trống' : '' }}
            </span>
      </ValidationProvider>
      <v-checkbox v-model="book.sale" class="mx-2" label="Giảm giá sách"></v-checkbox>
      <h4>Số tiền giảm giá</h4>
      <vuetify-money
          v-model="book.amount"
          v-bind:label="label"
          v-bind:placeholder="placeholder"
          v-bind:readonly="readonly"
          v-bind:disabled="disabled"
          v-bind:outlined="outlined"
          v-bind:clearable="clearable"
          v-bind:valueWhenIsEmpty="valueWhenIsEmpty"
          v-bind:options="options"
      />
      <h4 class="mb-6">Mô tả ngắn gọn</h4>
      <CkContent
          :content="book.short_content"
          @getData="(value) => book.short_content = value"
      ></CkContent>
      <h4 class="my-6">Mô tả</h4>
      <CkContent
          :content="book.description"
          @getData="(value) => book.description = value"
      ></CkContent>
    </ValidationObserver>
    <v-row class="d-flex align-center justify-center my-9">
      <v-col cols="4" class="d-flex align-center justify-center text-center">
        <HnrButton color="primary"
                   @hnrClickEvent="saveConfig"
                   class="text-body-1" :text="save"/>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import {UPLOAD_BOOK_IMAGE, SAVE_BOOK, UPLOAD_BOOK_MULTIPLE_IMAGE} from "@/store/book.module";

export default {
  components: {
    CkContent: () => import( "@/components/ck-content"),
    HnrButton: () => import("@/components/elements/hnr-button")
  },
  data() {
    return {
      book: {
        images: [],
      },
      imageError: "",
      imageError2: "",
      image: {},
      images: [],
      label: "",
      placeholder: "Nhập số tiền khóa học",
      readonly: false,
      disabled: false,
      outlined: true,
      clearable: true,
      valueWhenIsEmpty: "",
      options: {
        locale: "pt-BR",
        prefix: "VNĐ",
        suffix: "",
        length: 11,
        precision: 0,
      },
      save: 'Lưu'

    }
  },
  methods: {
    fileValidation(file_name_id) {
      var fileInput =
          document.getElementById(file_name_id);
      var filePath = fileInput.value;
      // Allowing file type
      var allowedExtensions =
          /(\.jpg|\.jpeg|\.png|\.gif)$/i;
      if (!allowedExtensions.exec(filePath)) {
        fileInput.value = '';
        return false;
      }
      return true;
    },
    onChangeFileUpload() {
      if (!this.fileValidation('file_input1')) {
        this.imageError = "File tải lên không đúng định dạng!";
        return null;
      } else {
        if (this.image) {
          this.imageError = "";
          let formData = new FormData();
          formData.append("upload", this.image);
          this.$store
              .dispatch(UPLOAD_BOOK_IMAGE, formData)
              .then((data) => {
                if (data.data.status == 1) {
                  this.book.url = data.data.data;
                  this.$forceUpdate()
                }
              })
        }
      }

    },
    onChangeMultipleFileUpload() {
      if (!this.fileValidation('file_input2')) {
        this.imageError2 = "File tải lên không đúng định dạng!";
        return null;
      } else {
        if (this.images) {
          this.imageError2 = "";
          let formData = new FormData();
          this.images.map((item,i) =>{
            formData.append(`uploads[${i}]`, item);
          })

          this.$store
              .dispatch(UPLOAD_BOOK_MULTIPLE_IMAGE, formData)
              .then((data) => {
                if (data.data.status == 1) {
                  data.data.data.map((image) =>{
                    this.book.images.push(image);
                    this.$forceUpdate()
                  })
                }
                else {
                  this.$toasted.error('File chưa đúng định dạng', {
                    position: "top-right",
                    duration: 3000
                  })
                }
              })
        }
      }

    },
    deleteImage(index) {
      if (this.book.images.length > 1) {
        this.book.images.splice(index, 1)
      } else {
        this.book.images = []
      }
      this.$forceUpdate()
    },
    clearImage() {
      this.url = "";
      this.book.url = "";
    },
    async saveConfig() {
      let result = await this.$refs.add_book.validate()
      if (result) {
        if (!this.book.short_content) {
          this.$toasted.error('Bạn chưa nhập mô tả ngắn gọn', {
            position: "top-right",
            theme: "toasted-primary",
            duration: 3000
          });
          return false;
        }
        if (!this.book.description) {
          this.$toasted.error('Bạn chưa nhập mô tả', {
            position: "top-right",
            theme: "toasted-primary",
            duration: 3000
          });
          return false;
        }
        if (this.book.sale) {
          if (!this.book.amount) {
            this.$toasted.error('Bạn chưa nhập số tiền giảm giá', {
              position: "top-right",
              theme: "toasted-primary",
              duration: 1000
            });
            return false;
          }
          else{
            if (parseFloat(this.book.amount) > parseFloat(this.book.price)) {
              this.$toasted.error('Số tiền giảm giá phải nhỏ hơn giá gốc !!!', {
                position: "top-right",
                theme: "toasted-primary",
                duration: 1000
              });
              return false;
            }
          }
        } else {
          this.book.amount = 0
        }
        if (this.book.url) {
          this.$store
              .dispatch(SAVE_BOOK, this.book)
              .then((data) => {
                if (data.data.status == 1) {
                  this.$toasted.success('Lưu thành công', {
                    position: "top-right",
                    theme: "toasted-primary",
                    duration: 1000
                  });
                  this.$router.push('/book/list')
                }
              })
        } else {
          this.$toasted.error('Bạn chưa chọn ảnh bìa', {
            position: "top-right",
            theme: "toasted-primary",
            duration: 1000
          });
        }
      } else {
        this.$toasted.error('Bạn chưa nhập đủ thông tin', {
          position: "top-right",
          theme: "toasted-primary",
          duration: 1000
        });
      }
    }
  }
}
</script>